// icons logo
import keenLogo1 from 'assets/icons/Keens/Logo/logo-1.svg';
import keenLogo2 from 'assets/icons/Keens/Logo/logo-2.svg';
import keenLogo3 from 'assets/icons/Keens/Logo/logo-3.svg';
import keenLogo4 from 'assets/icons/Keens/Logo/logo-4.svg';
import keenLogo5 from 'assets/icons/Keens/Logo/logo-5.svg';
import keenLogo6 from 'assets/icons/Keens/Logo/logo-6.svg';
import keenLogo7 from 'assets/icons/Keens/Logo/logo-7.svg';
// icons text
import keenIcToggleRight from 'assets/icons/Keens/Text/Toggle-Right.svg';
// icons general
import keenIcSearch from 'assets/icons/Keens/General/Search.svg';
import keenIcUser from 'assets/icons/Keens/General/User.svg';
import keenIcShieldCheck from 'assets/icons/Keens/General/Shield-check.svg';
// icons avatar
import keenIcAvatarBoy from 'assets/icons/Keens/Avatars/001-boy.svg';
import keenIcAvatarGirl from 'assets/icons/Keens/Avatars/002-girl.svg';
// icons flag
import keenIcFlagEn from 'assets/icons/Keens/Flags/226-united-states.svg';
import keenIcFlagVi from 'assets/icons/Keens/Flags/220-vietnam.svg';
// icons code
import keenIcCompiling from 'assets/icons/Keens/Code/Compiling.svg';
import keenIcCmd from 'assets/icons/Keens/Code/CMD.svg';
import keenIcLockOverturning from 'assets/icons/Keens/Code/Lock-overturning.svg';
// icons media
import keenIcEqualizer from 'assets/icons/Keens/Media/Equalizer.svg';
// icons layout
import keenIcLayout4Blocks from 'assets/icons/Keens/Layout/Layout-4-blocks.svg';
import keenIcLayoutPolygon from 'assets/icons/Keens/Layout/Layout-polygon.svg';
// icons communication
import keenIcCommunicationChat from 'assets/icons/Keens/Communication/Chat6.svg';
import keenIcCommunicationMailBox from 'assets/icons/Keens/Communication/Mail-box.svg';
import keenIcCommunicationSpam from 'assets/icons/Keens/Communication/Spam.svg';
import keenIcCommunicationSafeChat from 'assets/icons/Keens/Communication/Safe-chat.svg';
import keenIcCommunicationSend from 'assets/icons/Keens/Communication/Send.svg';
import keenIcCommunicationMailAt from 'assets/icons/Keens/Communication/Mail-at.svg';
import keenIcCommunicationChatLocked from 'assets/icons/Keens/Communication/Chat-locked.svg';
import keenIcCommunicationGroupChat from 'assets/icons/Keens/Communication/Group-chat.svg';
import keenIcCommunicationThumbtack from 'assets/icons/Keens/Communication/Thumbtack.svg';
import keenIcCommunicationOutgoingBox from 'assets/icons/Keens/Communication/Outgoing-box.svg';
import keenIcCommunicationClipboardCheck from 'assets/icons/Keens/Communication/Clipboard-check.svg';
import keenIcCommunicationGroup from 'assets/icons/Keens/Communication/Group.svg';
// icons clothes
import keenIcBriefcase from 'assets/icons/Keens/Clothes/Briefcase.svg';
import keenIcCrown from 'assets/icons/Keens/Clothes/Crown.svg';
// icons navigations
import keenIcNavigationUp from 'assets/icons/Keens/Navigation/Up-2.svg';
import keenIcNavigationArrowLeft from 'assets/icons/Keens/Navigation/Arrow-left.svg';
// icons tools
import keenIcToolCompass from 'assets/icons/Keens/Tools/Compass.svg';
// icons design
import keenIcDesignFlatten from 'assets/icons/Keens/Design/Flatten.svg';
// icons home
import keenIcLibrary from 'assets/icons/Keens/Home/Library.svg';
// icons files
import keenIcFilePlus from 'assets/icons/Keens/Files/File-plus.svg';
import keenIcUserBlank from 'assets/icons/Keens/Users/blank.png';

import icFlagEn from 'assets/images/flags/ic_flag_en.svg';
import icFlagUs from 'assets/images/flags/ic_flag_us.svg';
import icFlagVi from 'assets/images/flags/ic_flag_vi.svg';
import icLogoICorp from 'assets/icons/ic_logo_icorp.svg';
import icLogoICorpLight from 'assets/icons/box_logo.svg';
import icLogoEContract from 'assets/icons/ic_logo_econtract.svg';
import icLogoEContractLight from 'assets/icons/ic_logo_econtract_light.svg';
import icEmptyBox from 'assets/icons/ic_empty_box.svg';

// App resources: icons, images, fonts...
const AppResource = {
    // icons
    icons: {
        // keens
        keens: {
            // logo
            logo1: keenLogo1,
            logo2: keenLogo2,
            logo3: keenLogo3,
            logo4: keenLogo4,
            logo5: keenLogo5,
            logo6: keenLogo6,
            logo7: keenLogo7,
            // text
            toggleRight: keenIcToggleRight,
            // general
            search: keenIcSearch,
            user: keenIcUser,
            shieldCheck: keenIcShieldCheck,
            // avatar
            avatarBoy: keenIcAvatarBoy,
            avatarGirl: keenIcAvatarGirl,
            // flag
            flagEn: keenIcFlagEn,
            flagVi: keenIcFlagVi,
            // code
            compiling: keenIcCompiling,
            cmd: keenIcCmd,
            lockOverTurning: keenIcLockOverturning,
            // media
            equalizer: keenIcEqualizer,
            // layout
            layout4Blocks: keenIcLayout4Blocks,
            layoutPolygon: keenIcLayoutPolygon,
            // communication
            chat: keenIcCommunicationChat,
            mailbox: keenIcCommunicationMailBox,
            spam: keenIcCommunicationSpam,
            safeChat: keenIcCommunicationSafeChat,
            send: keenIcCommunicationSend,
            mailAt: keenIcCommunicationMailAt,
            chatLocked: keenIcCommunicationChatLocked,
            groupChat: keenIcCommunicationGroupChat,
            thumbtack: keenIcCommunicationThumbtack,
            outgoingTask: keenIcCommunicationOutgoingBox,
            clipboardCheck: keenIcCommunicationClipboardCheck,
            group: keenIcCommunicationGroup,
            // clothes
            briefcase: keenIcBriefcase,
            crown: keenIcCrown,
            // navigation
            up: keenIcNavigationUp,
            arrowLeft: keenIcNavigationArrowLeft,
            // tools
            compass: keenIcToolCompass,
            // design
            flatten: keenIcDesignFlatten,
            // home
            library: keenIcLibrary,
            // files
            filePlus: keenIcFilePlus,
            // user blank
            userBlank: keenIcUserBlank,
        },

        icFlagEn,
        icFlagUs,
        icFlagVi,
        icLogoICorp,
        icLogoICorpLight,
        icLogoEContract,
        icLogoEContractLight,
        icEmptyBox,
    },

    // images
    images: {
        // keens
        keens: {
            bg1: require('assets/images/Keens/bg1.jpg'),
            bg2: require('assets/images/Keens/bg2.jpg'),
            bg3: require('assets/images/Keens/bg3.jpg'),
        },

        img404: require('assets/images/404.jpg'),
        imgBgAuth: require('assets/images/bg_auth_02.jpg'),
        imgLogoText: require('assets/images/stores/img_shipee_logo_text.png'),
        imgDefaultLogo: require('assets/images/stores/img_default_logo.jpeg'),
        imgDefaultAvatar: require('assets/images/stores/img_default_avatar.png'),
        imgAuthenticationBg: require('assets/images/stores/img_authentication_bg.png'),
        imgAuthenticationBgMobile: require('assets/images/stores/img_authentication_bg_mobile.png'),
        imgEmpty: require('assets/images/stores/img_empty.png'),
        imgUpload: require('assets/images/stores/upload_image.jpg'),
        imgLogo: require('assets/images/stores/img_logo.png'),
        imgLogoTextWhite: require('assets/images/stores/img_logo_white.png'),
        imgCardStat: require('assets/images/stores/img_bg_card_stat.png'),
        imgEmptyView: require('assets/images/stores/img_empty_view.png'),
        imgSpx: require('assets/images/stores/img_spx.png'),
        imgJt: require('assets/images/stores/img_jt.png'),
        imgGhn: require('assets/images/stores/img_ghn.png'),
        imgGhtk: require('assets/images/stores/img_ghtk.png'),
        imgVnp: require('assets/images/stores/img_vnp.png'),
        imgVtp: require('assets/images/stores/img_vtp.png'),
        imgBookExample: require('assets/images/img_book_example.png'),

        // error states
        errorStates: {
            noMatchFound: require('assets/images/ErrorStates/No-match-found.png'),
            error404: require('assets/images/ErrorStates/404.png'),
            lostConnection: require('assets/images/ErrorStates/Lost-connection.png'),
            noSearchFound: require('assets/images/ErrorStates/No-search-found.png'),
            pageNotFound: require('assets/images/ErrorStates/Page-not-found.png'),
            paymentFailed: require('assets/images/ErrorStates/Payment-failed.png'),
            permanentlyDelete: require('assets/images/ErrorStates/Permanently-delete.png'),
            somethingWentWrong: require('assets/images/ErrorStates/Something-went-wrong.png'),
            unsubscribe: require('assets/images/ErrorStates/Unsubscribe.png'),
        }
    },

    // colors
    colors: {
        feature: '#E92E4E',
        primary: '#1890FF',
        yellow: '#FFB347',
        neutral: '#595959'
    }
};

export default AppResource;